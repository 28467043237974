import { post } from "@/api/api-client";

const url = import.meta.env.VITE_API_AUTHENTICATION_CONFIRM;

type State = "change_password_required" | "login_completed";

export interface Response {
  state: State;
}

export interface Request {
  interaction_id: string;
  mfa_skip?: boolean;
}

export const authenticationConfirm = (
  interactionId: string,
  mfaSkip?: boolean,
) => {
  const request = {
    interaction_id: interactionId,
    mfa_skip: mfaSkip,
  };
  return post<Request, Response>(url, request, {
    withCredentials: true,
  });
};
