import { postAuthenticationPasskeys } from "@/api/postAuthenticationPasskeys";
import { postPublicKeyCredentialRequestOptions } from "@/api/publicKeyCredentialRequestOptions";
import { useState } from "react";
import {
  parseRequestOptionsFromJSON,
  toAuthenticationResponseJson,
} from "../../../../packages/nid-common";
import { useWebAuthn } from "../../../../packages/nid-common/hooks/useWebAuthn";

export const useAuthenticatePasskey = (interactionId: string) => {
  const { getPublicKeyCredential, isConditionalMediationAvailable } =
    useWebAuthn();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const authenticate = async (param: {
    signal?: AbortSignal;
    loginId?: string;
    autofill: boolean;
  }) => {
    const { signal, loginId, autofill } = param;
    try {
      if (!autofill) {
        setIsSubmitting(true);
      }
      const requestOptions = await postPublicKeyCredentialRequestOptions(
        { loginId: loginId },
        signal,
      ).catch((e) => {
        return new Error(
          "PublicKeyCredentialRequestOptionsの取得に失敗しました",
          { cause: e },
        );
      });

      if (requestOptions instanceof Error) {
        throw requestOptions;
      }

      const options = parseRequestOptionsFromJSON(
        requestOptions.data.publicKey,
      );

      const publicKeyCredential = await getPublicKeyCredential({
        signal,
        mediation: autofill ? "conditional" : undefined,
        publicKey: options,
      }).catch((e) => {
        if (e instanceof DOMException && e.name === "AbortError") {
          return "aborted";
        }
        if (e instanceof DOMException && e.name === "NotAllowedError") {
          return "not_allowed";
        }
        return new Error("PublicKeyCredentialの取得に失敗しました", {
          cause: e,
        });
      });

      if (publicKeyCredential instanceof Error) {
        throw publicKeyCredential;
      }

      if (
        publicKeyCredential === "aborted" ||
        publicKeyCredential === "not_allowed"
      ) {
        return publicKeyCredential;
      }

      if (publicKeyCredential === null) {
        throw new Error("PublicKeyCredentialはnullになりました");
      }

      if (autofill) {
        setIsSubmitting(true);
      }

      const publicKeyCredentialJSON =
        toAuthenticationResponseJson(publicKeyCredential);

      return await postAuthenticationPasskeys(
        { interactionId, ...publicKeyCredentialJSON },
        signal,
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    authenticate,
    isSubmitting,
    isConditionalMediationAvailable,
  };
};
