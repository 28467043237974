import { useCallback, useState } from "react";

import type { Namespace, ParseKeys } from "i18next";

import { type Mode, type Resolver, useForm } from "react-hook-form";
import type { DefaultValues } from "react-hook-form";

/**
 * Nid form Hooks.
 *
 * @param props.onSubmit フォーム送信時のコールバック.
 * @param props.resolver フォームのバリデーションルールを設定する.
 *
 * @remarks onSubmitでrejectする場合は、対応するエラーメッセージコードを渡す.
 *
 * `onSubmit: () => new Promise((_, reject) => setTimeout(() => reject("mfa.backupCode.error.invalid"), 2000))`
 *
 * @returns register, handleSubmit, errorMessage, errors, isSubmitting.
 */
export default function useNidForm<
  InputType extends object,
  N extends Namespace<"login" | "signup" | "common">,
>(props: {
  mode?: Mode;
  onSubmit: (input: InputType) => Promise<void>;
  resolver: Resolver<InputType, object>;
  defaultValues?: DefaultValues<InputType> | undefined;
}) {
  const [apiError, setApiError] = useState<ParseKeys<N> | undefined>(undefined);

  /** Form hook. */
  const {
    register,
    handleSubmit,
    formState,
    watch,
    reset,
    setValue,
    getValues,
    setFocus,
    setError,
  } = useForm<InputType>({
    mode: props.mode,
    resolver: props.resolver,
    defaultValues: props.defaultValues,
    shouldUnregister: true,
  });

  /** Some user's input event happens. */
  if (formState.isValidating) {
    /** Clear api error message. */
    apiError !== undefined && setApiError(undefined);
  }

  /** Submit handler with catching errors. */
  const onSubmitWrapper = useCallback(
    async (input: InputType) => {
      return await props.onSubmit(input).catch((error) => {
        setApiError(error);
      });
    },
    [props.onSubmit, setApiError],
  );

  return {
    register,
    handleSubmit: () => handleSubmit(onSubmitWrapper),
    apiError,
    fieldErrors: formState.errors as unknown as {
      [item: string]: { message: ParseKeys<N> };
    },
    isSubmitting: formState.isSubmitting,
    watch,
    reset,
    setValue,
    getValues,
    setFocus,
    setError,
  };
}
