import type { AxiosResponse } from "axios";

import { post } from "@/api/api-client";

const url = import.meta.env.VITE_API_AUTHENTICATION_ID;

export type Request = {
  login_id: string;
  interaction_id: string;
};

type State = "login_id_required" | "login_id_specified";

type Opt = "invalid_login_id" | "user_not_found" | "passkey_possession";

export type Response = {
  state: State;
  opt?: Opt;
};

export const authenticationId = (
  loginId: string,
  interactionId: string,
): Promise<AxiosResponse<Response>> => {
  return post<Request, Response>(
    url,
    { login_id: loginId, interaction_id: interactionId },
    {
      withCredentials: true,
    },
  );
};
