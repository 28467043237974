import {
  clientIdStateKey,
  interactionIdStateKey,
} from "@/contexts/RequestContext";

const [rpid, interactionId] = (() => {
  const jwt = new URLSearchParams(location.search).get("auth");
  if (jwt !== null) {
    const base64Url = jwt.split(".")[1] ?? "";
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const json = (() => {
      try {
        return JSON.parse(
          decodeURIComponent(encodeURIComponent(window.atob(base64))),
        );
      } catch (_) {
        return undefined;
      }
    })();
    return [json?.client_id, json?.sub];
  }

  const interactionId = (() => {
    const state = sessionStorage.getItem(interactionIdStateKey);
    if (!state) {
      return undefined;
    }
    try {
      return JSON.parse(state).interactionId;
    } catch (_) {
      return undefined;
    }
  })();

  const rpid = (() => {
    const state = sessionStorage.getItem(clientIdStateKey);
    if (!state) {
      return undefined;
    }
    try {
      return JSON.parse(state);
    } catch (_) {
      return undefined;
    }
  })();

  return [rpid, interactionId];
})();

window.analyticsVar = {
  context: {
    app: "interaction",
    app_version: import.meta.env.VITE_APP_VERSION,
    rpid: rpid,
    interaction_id: interactionId,
  },
};
