import type { AxiosResponse } from "axios";
import axios from "axios";
import type { CredentialRequestOptionsJSON } from "nid-common";

export interface PublicKeyCredentialRequestOptionsRequest {
  loginId?: string;
}

export const postPublicKeyCredentialRequestOptions = (
  body: PublicKeyCredentialRequestOptionsRequest,
  signal?: AbortSignal,
): Promise<AxiosResponse<CredentialRequestOptionsJSON>> => {
  return axios.post(
    import.meta.env.VITE_API_AUTHENTICATION_PASSKEYS_REQUEST_OPTIONS,
    body,
    { signal: signal },
  );
};
