import { Notification as NidNotification } from "nid-common";
import { Box, Link, Typography2 } from "nikkei-ui";
import { Trans, useTranslation } from "react-i18next";
import styles from "./Notification.module.css";

export const Notification = () => {
  const { t } = useTranslation("common");

  return (
    <NidNotification>
      <Box className={styles.notification}>
        <Typography2>
          <Trans
            t={t}
            i18nKey="message.notification"
            components={[
              <Link
                key="maintenance-details"
                href="/nl/maintenance_notice.html"
                type="text"
                newTab
              />,
            ]}
          />
        </Typography2>
      </Box>
    </NidNotification>
  );
};

export default Notification;
