import axios, {
  type AxiosError,
  type AxiosResponse,
  type RawAxiosRequestConfig,
} from "axios";

import type { ErrorResponse } from "@/@types/login";

const errorResponse = (error: string, status: number): ErrorResponse => {
  return { error, status };
};

/**
 * ベースURLを付与したAPIに対してリクエストを送信する.
 * エラーになるのは 400 以上のステータスコードのとき.
 *
 * @param url ベースURLからの絶対パス.
 * @param request リクエスト.
 * @returns レスポンスとエラーのUnion型.
 */
export const post = async <Request, Response>(
  url: string,
  request: Request,
  config: RawAxiosRequestConfig<Request> | undefined = undefined,
): Promise<AxiosResponse<Response>> => {
  return axios
    .create({ validateStatus: (status) => status < 400 })
    .post<Response>(url, request, config)
    .catch((err: AxiosError<{ error: string }>) => {
      const res = err.response;
      if (res !== undefined) {
        throw errorResponse(res.data.error, res.status);
      }
      throw errorResponse(err.message, 0);
    });
};

export const postForm = async <
  Request extends Record<string, string>,
  Response,
>(
  url: string,
  request: Request,
  config: RawAxiosRequestConfig<URLSearchParams> | undefined = undefined,
): Promise<AxiosResponse<Response>> =>
  post(url, new URLSearchParams(request), config);
